import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from './components/Header';
import AddLeadPopup from './components/AddLeadPopup';

const Home = () => {
    const [toggleLeadPopup, setToggleLeadPopup] = useState(0);
    const [designation, setDesignation] = useState();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setDesignation(userData.designation);
            // console.log(userData);
        }
    }, []);

    const closeLeadPopup = () => {
        setToggleLeadPopup(0);
    }
    const reload = () => {

    }

    return (
        <>

            {toggleLeadPopup === 1 ?
                <AddLeadPopup reload={reload} close={closeLeadPopup} />
                : <></>
            }
            <Header />

            <div className="crm-container">
                <div className='dashboard-cards'>

                    {designation == "A" || designation == "B" ?
                        <div className='crm-card'>
                            <Link to='/leads' className='crm-bg-green'>
                                <i className='fa fa-users'></i>
                            </Link>
                            <h2 className='crm-color-green'>Leads</h2>
                        </div>
                        : <></>
                    }

                    {designation == "A" || designation == "C" ?
                        <div className='crm-card'>
                            <Link to='/new-leads' className='crm-bg-primary'>
                                <i className='fa fa-list'></i>
                            </Link>
                            <h2 className='crm-color-primary'>New Leads</h2>
                        </div>
                        : <></>
                    }

                    {designation == "A" || designation == "C" ?
                        <div className='crm-card'>
                            <Link to='/new-visits' className='crm-bg-green'>
                                <i className='fa fa-eye'></i>
                            </Link>
                            <h2 className='crm-color-green'>New Visits</h2>
                        </div>
                        : <></>
                    }

                    {designation == "A" || designation == "D" ?
                        <div className='crm-card'>
                            <Link to='/my-leads' className='crm-bg-green'>
                                <i className='fa fa-users'></i>
                            </Link>
                            <h2 className='crm-color-green'>My Leads</h2>
                        </div>
                        : <></>
                    }

                    {designation == "A" || designation == "F" ?
                        <div className='crm-card'>
                            <Link to='/my-all-leads' className='crm-bg-green'>
                                <i className='fa fa-users'></i>
                            </Link>
                            <h2 className='crm-color-green'>My All Leads</h2>
                        </div>
                        : <></>
                    }

                    {designation == "A" || designation == "D" ?
                        <div className='crm-card'>
                            <Link to='my-followups' className='crm-bg-pink'>
                                <i className='fa fa-list'></i>
                            </Link>
                            <h2 className='crm-color-pink'>Follow Up</h2>
                        </div>
                        : <></>
                    }

                    {designation == "A" || designation == "C" ?
                        <div className='crm-card'>
                            <Link to='/all-follow-ups' className='crm-bg-pink'>
                                <i className='fa fa-users'></i>
                            </Link>
                            <h2 className='crm-color-pink'>All Follow Up</h2>
                        </div>
                        : <></>
                    }

                    {designation == "A" || designation == "B" ?
                        <div className='crm-card'>
                            <Link onClick={() => setToggleLeadPopup(1)} className='crm-bg-primary'>
                                <div>
                                    <i className='fa fa-user-plus'></i>
                                </div>
                            </Link>
                            <h2 className='crm-color-primary'>Add New Leads</h2>
                        </div>
                        : <></>
                    }

                    {designation == "A" || designation == "E" ?
                        <div className='crm-card'>
                            <Link to='/my-visits' className='crm-bg-green'>
                                <i className='fa fa-users'></i>
                            </Link>
                            <h2 className='crm-color-green'>My Visits</h2>
                        </div>
                        : <></>
                    }
                    {designation == "A" || designation == "E" ?
                        <div className='crm-card'>
                            <Link to='pending-visits' className='crm-bg-pink'>
                                <i className='fa fa-circle-o-notch'></i>
                            </Link>
                            <h2 className='crm-color-pink'>Pending Visits</h2>
                        </div>
                        : <></>
                    }
                </div>
            </div>
        </>

    );
}

export default Home;