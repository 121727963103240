import react, { useEffect, useState } from 'react';
import PopUpLoader from './PopUpLoader';
import axios from 'axios';

const VisitDonePopup = (props) => {

    const [userDetails, setUserDetails] = useState([]);
    const [addResponseColumns, setAddResponseColumns] = useState([]);
    const [loadingToggle, setLoadingToggle] = useState(0);
    const [alertToggle, setAlertToggle] = useState(0);
    const [alertMessage, setAlertMessage] = useState("Response successfully added");
    const [alertColor, setAlertColor] = useState("crm-color-green");



  
    const [response, setResponse] = useState("");
 
    const [visitedDate, setVisitedDate] = useState("");
    const [visitedTime, setVisitedTime] = useState("");

    const url = window.url;
    const qry = new FormData();

    const fullDate = new Date();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setUserDetails(userData);
            // console.log(userData);
            qry.append("emp_id", userData.user_id);

        }

    }, []);

   

    const addResponseSubmit = (e) => {
        e.preventDefault();


        const addResponseData = new FormData();
        addResponseData.append("lead_id", props.leadId);
        addResponseData.append("emp_id", userDetails.user_id);
        addResponseData.append("designation", userDetails.designation);
        addResponseData.append("response", response);
        addResponseData.append("visited_date", visitedDate);
        addResponseData.append("visited_time", visitedTime);
       
            setLoadingToggle(1);
            axios.post(url + "visit-done.php", addResponseData).then(response => {
                // console.log(response.data);

                if (response.data.status == 1) {
                    setLoadingToggle(0);
                    setAlertMessage(response.data.msg);
                    setAlertToggle(1);

                    setTimeout(() => {
                        props.close();
                    }, 1000);
                }

                props.reload();

            }).catch(error => {
                console.log(error);
            })
        
        // console.log(props.leadId + userDetails.user_id + callingDate + callingTime, phoneNo, tagName, message, nextFollowDate, nextFollowTime, visitingDate);
    }


    return (
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>

                    {loadingToggle == 1 ?
                        <PopUpLoader />
                        : <></>
                    }

                    {alertToggle == 1 ?
                        <div className='crm-alert'>
                            <p className={alertColor}> {alertMessage} </p>
                        </div>
                        : <></>
                    }

                    <div className='add-new-head'>
                        <h2> Visit Done <i className='fa fa-check'></i> </h2>
                        <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form onSubmit={addResponseSubmit}>

                            <label> Visited Date/Time* </label>
                            <br />
                            <input type='date' className='form-input' max={fullDate.getFullYear()+"-"+String(fullDate.getMonth()+1).padStart(2,'0')+"-"+String(fullDate.getDate()).padStart(2,'0')} value={visitedDate} onChange={(e) => setVisitedDate(e.target.value)} required />
                            <input type='time' className='form-input' value={visitedTime} onChange={(e) => setVisitedTime(e.target.value)} required />

                          
                            <label> Response </label>
                            <br />
                            <textarea name="message" placeholder='response' value={response} onChange={(e) => setResponse(e.target.value)} required></textarea>

                            <button type='submit' className='crm-btn-primary'> Submit </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default VisitDonePopup;