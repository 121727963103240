import React from "react";

const TableLoader = () => {

    return (<>
        <div className='table-loader'>
            <div></div>
        </div>
    </>);
}
export default TableLoader;