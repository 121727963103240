import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Brand from './img/brand.svg';
import axios from "axios";


const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [alert, setalert] = useState(0);
    const [alertMsg, setAlertMsg] = useState("");


    const url = window.url;
    const qry = new FormData();

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            // console.log(userData);
            navigate("/");
        }
    }, []);
    const Login = (e) => {
        e.preventDefault();
        // alert(username+password);
        // console.log(username+password);
        setAlertMsg("");
        setalert(0);

        qry.append("username", username);
        qry.append("password", password);

        var info;

        axios.post(url + "login.php", qry).then((response) => {
            if (response.data.status == 1) {
                // console.log(response.data);
                info = { "user_id": response.data.info.id, "username": username, "name": response.data.info.name, "designation": response.data.info.designation };
                localStorage.setItem('user', JSON.stringify(info));
                navigate("/");
            }
            else {
                setAlertMsg(response.data.msg);
                setalert(1);
            }

            // console.log(response.data);

        }).catch(error => {
            console.log("error");
        });

        // if (username == "7669845546") {
        //     info = { "user_id": 1, "username": username, "name":"Dipesh", "designation": "A" };
        //     localStorage.setItem('user', JSON.stringify(info));
        // }
        // else {

        // }

    }

    return (
        <>
            <div className="login-page">
                <img className='' src={Brand} alt="swarnbhoomii-logo" />

                <div className="login-card">
                    <h2> CRM    </h2>
                    <div>
                        <h3> Log In </h3>

                        <form onSubmit={(e) => Login(e)}>
                            <label>Username</label>
                            <br />
                            <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                            <br />
                            <label> Password </label>
                            <br />
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                            <br />
                            {alert ?
                                <>
                                    <br />
                                    <p className="crm-color-red"> {alertMsg} </p>
                                </>
                                : <></>
                            }

                            <button className=""> Log In</button>
                            <Link to="/reset-password">reset password ?</Link>
                        </form>

                    </div>
                </div>
            </div>

        </>
    );
}

export default Login;
