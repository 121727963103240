import react, { useEffect, useState } from 'react';
import PopUpLoader from './PopUpLoader';
import axios from 'axios'

const AssignToFollowups = (props) => {
    const [usersData, setUsersData] = useState([]);
    const [userId, setUserId] = useState("");
    const [filter, setFilter] = useState("");
    const [loadingToggle, setLoadingToggle] = useState(0);
    const [alertToggle, setAlertToggle] = useState(0);
    const [alertMessage, setAlertMessage] = useState("Lead successfully added");
    const [alertColor, setAlertColor] = useState("crm-color-green");

    const url = window.url;
    const qry = new FormData();

    useEffect(() => {
        fetchUsersApi();
    }, []);

    const fetchUsersApi = () => {
        qry.append("emp_id", "");
        axios.post(url + "fetch-users.php", qry).then((response) => {
            if (response.data.status == 1) {
                setUsersData(response.data.data);
                // console.log(response.data);
            }
        }).catch(error => {
            console.log("error");
        });
    }

    const assignApi = (e) => {
        e.preventDefault();

        setLoadingToggle(1);

        const data = new FormData();
        data.append("emp_id", props.emp_id);
        data.append("data", props.data);
        data.append("user", userId);

        axios.post(url + "assign-to-followup.php", data).then((response) => {
            if (response.data.status == 1) {
                setLoadingToggle(0);
                setAlertMessage(response.data.msg);
                setAlertToggle(1);

                
                setTimeout(()=>{
                    // props.close();
                    props.reload(); 
                },500);
            }
            console.log(response.data);
        }).catch(error => {
            console.log("error");
        });
    }

    return (
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>

                    {loadingToggle == 1 ?
                        <PopUpLoader />
                        : <></>
                    }

                    {alertToggle == 1 ?
                        <div className='crm-alert'>
                            <p className={alertColor}> {alertMessage} </p>
                        </div>
                        : <></>
                    }

                    <div className='add-new-head'>
                        <h2> Assign to Follow Ups <i className='fa fa-forward'></i> </h2>
                        <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                    <p className='crm-color-green' style={{ "margin": "10px 0" }}>{props.data.length} {props.data.length == 0 ? "Leads Selected" : "Leads Assigning"} </p>                        <form onSubmit={assignApi}>

                            <label> Sales Representative </label>

                            <select onChange={(e) => setFilter(e.target.value)} required disabled={props.data.length == 0 ? "disabled" : ""}>
                                <option value=""> select </option>
                                <option value="D"> Follow Up </option>
                                <option value="F"> Both </option>
                            </select>

                            {/* <label> For Site </label>
                         
                            <select required>
                                <option value=""> select </option>
                                <option value=""> Anmol Vihar </option>
                                <option value=""> Supreme Field </option>
                            </select> */}

                            <label> Coordinator </label>

                            <select required onChange={(e) => setUserId(e.target.value)} disabled={props.data.length == 0 ? "disabled" : ""}>
                                <option value=""> select </option>

                                {usersData.length != 0 ?
                                    <>
                                        {
                                            usersData.filter(item => item.designation == filter).map(data => {
                                                return (
                                                    <option value={data.id}> {data.name} </option>
                                                )
                                            })
                                        }
                                    </> :
                                    <></>
                                }
                            </select>

                            <button type='submit' className='crm-btn-primary' disabled={props.data.length == 0 ? "disabled" : ""}> Assign <i className='fa fa-forward'></i> </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AssignToFollowups;