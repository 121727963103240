import React from "react";

const FullPageLoader = () => {

    return (<>
        <div className='full-page-loader'>
            <div className='loading'>
                <i className='fa fa-circle-o-notch'></i> <br />
                Loading...
            </div>
        </div>
    </>);
}

export default FullPageLoader;