import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Leads from "./Leads";
import ViewLeadDetails from "./ViewLeadDetails";
import Login from "./Login";
import PendingFollowUps from "./PendingFollowUps";
import NewLeads from "./NewLeads";
import NewVisits from "./NewVisits";
import AllFollowUps from "./AllFollowUps";
import MyLeads from "./MyLeads";
import MyVisits from "./MyVisits";
import CallerFollowUps from "./CallerFollowUps";
import CallerNewFollowUps from "./CallerNewFollowUps";
import NewR2V from "./NewR2V";
import PendingVisits from "./PendingVisits";
import VisitsDone from "./VisitsDone";
import MyLeadsRM from "./MyLeadsRM";
import ResetPassword from "./ResetPassword";
import OutOfInventory from "./OutOfInventory";
import WorkingDone from "./WorkingDone";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/leads" element={<Leads />} exact />
        <Route path="/my-leads" element={<MyLeads />} exact />
        <Route path="/my-all-leads" element={<MyLeadsRM />} exact />
        <Route path="/new-followups" element={<CallerNewFollowUps />} exact />
        <Route path="/my-followups" element={<CallerFollowUps />} exact />
        <Route path="/out-of-inventory" element={<OutOfInventory />} exact />
        <Route path="/working-done" element={<WorkingDone />} exact />
        <Route path="/my-visits" element={<MyVisits />} exact />
        <Route path="/new-r2v" element={<NewR2V />} exact />
        <Route path="/pending-visits" element={<PendingVisits />} exact />
        <Route path="/visits-done" element={<VisitsDone />} exact />
        {/* <Route path="/pending-follow-ups" element={<PendingFollowUps />} exact /> */}
        <Route path="/all-follow-ups" element={<AllFollowUps />} exact />
        <Route path="/new-leads" element={<NewLeads />} exact />
        <Route path="/new-visits" element={<NewVisits />} exact />
        <Route path="/view-lead-details/:leadId" element={<ViewLeadDetails />} exact />
        <Route path="/view-lead-details/:leadId/:visit" element={<ViewLeadDetails />} exact />
        <Route path="/login" element={<Login />} exact />
        <Route path="/reset-password" element={<ResetPassword />} exact />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
