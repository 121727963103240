import react, { useEffect, useState } from 'react';
import PopUpLoader from './PopUpLoader';
import axios from 'axios';

const AddResponsePopup = (props) => {
    const [userDetails, setUserDetails] = useState([]);
    const [addResponseColumns, setAddResponseColumns] = useState([]);
    const [loadingToggle, setLoadingToggle] = useState(0);
    const [alertToggle, setAlertToggle] = useState(0);
    const [alertMessage, setAlertMessage] = useState("Response successfully added");
    const [alertColor, setAlertColor] = useState("crm-color-green");
    const [niStatus, setNiStatus] = useState(1);


    const [callingDate, setCallingDate] = useState("");
    const [callingTime, setCallingTime] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [tagName, setTagName] = useState("");
    const [tagId, setTagId] = useState("");
    const [message, setMessage] = useState("");
    const [nextFollowDate, setNextFollowDate] = useState("");
    const [nextFollowTime, setNextFollowTime] = useState("");
    const [visitingDate, setVisitingDate] = useState("");
    const [visitingTime, setVisitingTime] = useState("");

    const url = window.url;
    const qry = new FormData();

    const fullDate = new Date();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setUserDetails(userData);
            // console.log(userData);
            qry.append("emp_id", userData.user_id);

        }

        addResponseColumnsApi();

    }, []);

    const addResponseColumnsApi = () => {
        axios.post(url + "fetch-add-response-columns.php", qry).then((response) => {
            if (response.data.status == 1) {
                setAddResponseColumns(response.data);
                // console.log(response.data);
            }

        }).catch(error => {
            console.log("error");
        });
    }

    const addResponseSubmit = (e) => {
        e.preventDefault();


        const addResponseData = new FormData();
        addResponseData.append("lead_id", props.leadId);
        addResponseData.append("emp_id", userDetails.user_id);
        addResponseData.append("designation", userDetails.designation);
        addResponseData.append("visit_follow", props.visit);
        addResponseData.append("call_date", callingDate);
        addResponseData.append("call_time", callingTime);
        addResponseData.append("phone_no", phoneNo);
        addResponseData.append("tag_name", tagName);
        addResponseData.append("response", message);
        addResponseData.append("next_follow_date", nextFollowDate);
        addResponseData.append("next_follow_time", nextFollowTime);
        addResponseData.append("visiting_date", visitingDate);
        addResponseData.append("visiting_time", visitingTime);

        if (tagName == "") {
            alert("please select response tag");
        } else {
            setLoadingToggle(1);
            axios.post(url + "add-new-response.php", addResponseData).then(response => {
                console.log(response.data);

                if (response.data.status == 1) {
                    setLoadingToggle(0);
                    setAlertMessage(response.data.msg);
                    setAlertToggle(1);

                    setTimeout(() => {
                        props.close();
                    }, 500);
                }

                props.reload();

            }).catch(error => {
                console.log(error);
            })
        }
        // console.log(props.leadId + userDetails.user_id + callingDate + callingTime, phoneNo, tagName, message, nextFollowDate, nextFollowTime, visitingDate);
    }

    const tagChange = (id, name) => {

        if (id == -1) {
            setTagId(id);
            setTagName(name);
            setNiStatus(1);
        }
        else {
            setVisitingDate("");
            setVisitingTime("");
            setTagId(id);
            setTagName(name);
            setNiStatus(1);
        }

        if (id == -3) {
            setNiStatus(0);
            setNextFollowDate("NA");
            setNextFollowTime("NA");
        }
    }

    return (
        <>
            <div className="dark-background">
                <div className='add-new-lead-popup'>

                    {loadingToggle == 1 ?
                        <PopUpLoader />
                        : <></>
                    }

                    {alertToggle == 1 ?
                        <div className='crm-alert'>
                            <p className={alertColor}> {alertMessage} </p>
                        </div>
                        : <></>
                    }

                    <div className='add-new-head'>
                        <h2> Add Response <i className='fa fa-reply'></i> </h2>
                        <i className='fa fa-times cross' onClick={props.close}></i>
                    </div>

                    <div className='form'>
                        <form onSubmit={addResponseSubmit}>

                            <label> Calling Date/Time* </label>
                            <br />
                            <input type='date' className='form-input' max={fullDate.getFullYear() + "-" + String(fullDate.getMonth() + 1).padStart(2, '0') + "-" + String(fullDate.getDate()).padStart(2, '0')} value={callingDate} onChange={(e) => setCallingDate(e.target.value)} required />
                            <input type='time' className='form-input' value={callingTime} onChange={(e) => setCallingTime(e.target.value)} required />

                            <select value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)} required>
                                <option value=""> Call By (Phone No) </option>

                                {addResponseColumns != [] && addResponseColumns.alter_num != null ?
                                    addResponseColumns.alter_num.map(data => {
                                        return (
                                            <>
                                                <option value={data.phone}> +91 {data.phone} </option>
                                            </>
                                        )
                                    })
                                    : <></>

                                }
                            </select>


                            <div className='response-tags'>

                                {addResponseColumns != [] && addResponseColumns.tags != null ?
                                    addResponseColumns.tags.map(data => {
                                        return (
                                            <>
                                                <div className={tagId == data.id ? 'tag-clicked' : 'tag'} onClick={() => tagChange(data.id, data.tag_name)} > {data.tag_name} </div>
                                            </>
                                        )
                                    })
                                    : <></>

                                }

                                <div className={tagId == -1 ? 'tag crm-bg-green crm-color-white' : 'tag crm-bg-white crm-color-green'} onClick={() => tagChange(-1, "Ready")} > Ready for Visit </div>
                                <div className={tagId == -2 ? 'tag crm-bg-pink crm-color-white' : 'tag crm-bg-white crm-color-pink'} onClick={() => tagChange(-2, "talked")} > Talked </div>
                                {/* <div className={tagId == -5 ? 'tag crm-bg-green crm-color-white' : 'tag crm-bg-white crm-color-green'} onClick={() => tagChange(-5, "talked")} > Visit Done </div> */}
                                <div className={tagId == -3 ? 'tag-clicked' : 'tag'} onClick={() => tagChange(-3, "ni")} > NI </div>
                            </div>

                            {tagId == -1 ? <>
                                <label> Visiting Date/Time* </label>
                                <br />
                                <input type='date' className='form-input' value={visitingDate} onChange={(e) => setVisitingDate(e.target.value)} required />
                                <input type='time' className='form-input' value={visitingTime} onChange={(e) => setVisitingTime(e.target.value)} required />
                            </>
                                : <></>}

                            <label> Message* </label>
                            <br />
                            <textarea name="message" placeholder='message' value={message} onChange={(e) => setMessage(e.target.value)} required></textarea>

                            {niStatus == 1 ?<>
                                <label> Next Follow Up Date/Time* </label>
                                <br />
                                <input type='date' className='form-input' value={nextFollowDate} onChange={(e) => setNextFollowDate(e.target.value)} required />
                                <input type='time' className='form-input' value={nextFollowTime} onChange={(e) => setNextFollowTime(e.target.value)} required />
                            </>:<></>
                            }
                            <button type='submit' className='crm-btn-primary'> Submit </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddResponsePopup;