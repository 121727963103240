import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import AddLeadPopup from './components/AddLeadPopup';
import axios from 'axios';

const Leads = () => {
    const [toggleLeadPopup, setToggleLeadPopup] = useState(0);
    const [userDetails, setUserDetails] = useState([]);
    const [filterStatus, setfilterStatus] = useState("");

    const [clientData, setClientData] = useState([]);

    const url = window.url;
    const qry = new FormData();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            setUserDetails(userData);
            // console.log(userData);

            if (userData.designation == "B") {

            }
            else {
                navigate("/");
            }

            qry.append("emp_id", userData.user_id);

        }
        fetchMyLeadsApi();
    }, []);

    const reload = () => {
        qry.append("emp_id", userDetails.user_id);
        fetchMyLeadsApi();
    }
    const fetchMyLeadsApi = () => {
        axios.post(url + "fetch-my-leads.php", qry).then((response) => {
            if (response.data.status == 1) {
                setClientData(response.data.data);
                // console.log(response.data);
            }

        }).catch(error => {
            console.log("error");
        });
    }




    const closeLeadPopup = () => {
        setToggleLeadPopup(0);
    }

    var i = 0;
    const index = () => {
        i = i + 1;
    }

    return (
        <>

            {toggleLeadPopup === 1 ?
                <AddLeadPopup reload={reload} close={closeLeadPopup} />
                : <></>
            }

            <Header />

            <div className='container-head'>
                <div>
                    <h2> My Leads</h2>

                    <div className='filter'>

                        <select onChange={(e) => setfilterStatus(e.target.value)}>
                            <option value=""> Filter Status</option>
                            <option value="3"> Follow Up</option>
                            <option value="4"> R2V </option>
                            <option value="5"> Visited</option>
                            <option value="6"> Closed</option>
                            <option value="7"> Payment Done</option>
                            <option value="8"> Not Interested</option>
                        </select>
                    </div>

                </div>
                <div>
                    <button className='crm-btn-primary' onClick={() => { setToggleLeadPopup(1) }}> Add New <i className='fa fa-user-plus'></i> </button>
                </div>
            </div>

            <div className='crm-container'>
                <div className='crm-table visible-hide'>

                    <table>
                        <thead>
                            <tr> <th> S No</th> <th> Lead Date </th> <th> Name </th> <th> Phone </th> <th> Email </th> <th> Status </th> <th> Action </th></tr>
                        </thead>

                        {
                            clientData.length != 0 ?
                                <>
                                    {filterStatus != "" ?
                                        clientData.filter((item) => item.process_status == filterStatus).map((data) => {
                                            index();
                                            return (
                                                <tr><td> {i}</td> <td>{data.lead_date}</td> <td>{data.name}</td> <td> {data.phone} </td> <td>{data.email}</td> <td className='crm-color-primary'> {data.process_status == 1 ? "New" : data.process_status == 2 ? "New" : data.process_status == 3 ? "Follow Up" : data.process_status == 4 ? "R2V" : data.process_status == 5 ? "Visited" : data.process_status == 6 ? "Closed" : data.process_status == 7 ? "Payment Done" : data.process_status == 8 ? "Not Interested" : ""} </td> <td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td></tr>
                                            );
                                        })
                                        : clientData.map((data) => {
                                            index();
                                            return (
                                                <tr><td> {i}</td> <td>{data.lead_date}</td> <td>{data.name}</td> <td> {data.phone} </td> <td>{data.email}</td> <td className='crm-color-primary'> {data.process_status == 1 ? "New" : data.process_status == 2 ? "New" : data.process_status == 3 ? "Follow Up" : data.process_status == 4 ? "R2V" : data.process_status == 5 ? "Visited" : data.process_status == 6 ? "Closed" : data.process_status == 7 ? "Payment Done" : data.process_status == 8 ? "Not Interested" : ""} </td> <td> <Link to={'/view-lead-details/' + data.id}>View </Link> </td></tr>
                                            );
                                        })
                                    }
                                </>
                                : <><tr><td>Empty</td></tr></>
                        }
                    </table>
                </div>

                <div className='crm-phone-table hide-visible'>
                    <p style={{ "display": "none" }}>{i = 0}</p>
                    {
                        clientData.length != 0 ?
                            <>
                                {filterStatus != "" ?
                                    clientData.filter((item) => item.process_status == filterStatus).map((data) => {
                                        index();
                                        return (
                                            <>
                                                <div className='phone-table-item'>
                                                    <div className='table-item-left'>
                                                        <h2> {i} </h2>
                                                    </div>
                                                    <div className='table-item-right'>
                                                        <div>
                                                            <h2>{data.name}</h2>
                                                        </div>
                                                        <div>
                                                            <h2>+91 {data.phone} </h2>
                                                        </div>
                                                        <div>
                                                            <p> {data.email} </p>
                                                        </div>
                                                        <div>
                                                            <p> Lead Date : {data.lead_date} </p>
                                                        </div>
                                                        <div>
                                                            <p className="crm-color-primary">{data.process_status == 1 ? "New" : data.process_status == 2 ? "New" : data.process_status == 3 ? "Follow Up" : data.process_status == 4 ? "R2V" : data.process_status == 5 ? "Visited" : data.process_status == 6 ? "Closed" : data.process_status == 7 ? "Payment Done" : data.process_status == 8 ? "Not Interested" : ""} </p>

                                                            <Link to={'/view-lead-details/' + data.id}>View </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                    : clientData.map((data) => {
                                        index();
                                        return (
                                            <>
                                                <div className='phone-table-item'>
                                                    <div className='table-item-left'>
                                                        <h2> {i} </h2>
                                                    </div>
                                                    <div className='table-item-right'>
                                                        <div>
                                                            <h2>{data.name}</h2>
                                                        </div>
                                                        <div>
                                                            <h2>+91 {data.phone} </h2>

                                                        </div>
                                                        <div>
                                                            <p> {data.email} </p>
                                                        </div>
                                                        <div>
                                                            <p> Lead Date: {data.lead_date} </p>
                                                        </div>
                                                        <div>
                                                            <p className="crm-color-primary">{data.process_status == 1 ? "New" : data.process_status == 2 ? "New" : data.process_status == 3 ? "Follow Up" : data.process_status == 4 ? "R2V" : data.process_status == 5 ? "Visited" : data.process_status == 6 ? "Closed" : data.process_status == 7 ? "Payment Done" : data.process_status == 8 ? "Not Interested" : ""} </p>

                                                            <Link to={'/view-lead-details/' + data.id}>View </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    })
                                }
                            </>
                            : <><tr><td>Empty</td></tr></>
                    }
                </div>
            </div>
        </>
    )
}

export default Leads;