import React from 'react';

const PopUpLoader = () => {

    return (<>

        <div className='pop-up-loader'>
            <div className='loading'>
                <i className='fa fa-circle-o-notch'></i> <br />
                Loading...
            </div>
        </div>

    </>);
}


export default PopUpLoader;