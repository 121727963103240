import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Brand from './img/brand.svg';
import axios from "axios";


const ResetPassword = () => {
    const [username, setUsername] = useState("");
    const [otp, setOtp] = useState("");
    const [otpEmail, setOtpEmail] = useState("");

    const [newPassword, setNewPassword] = useState("");
    const [rePassword, setRePassword] = useState("");

    const [alert, setalert] = useState(0);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertColor, setAlertColor] = useState("crm-color-red");

    const [card, setCard] = useState(1);

    const url = window.url;
    const qry = new FormData();

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("user")) {
            const userData = JSON.parse(localStorage.getItem("user"));
            // console.log(userData);
            navigate("/");
        }
    }, []);
    const checkUser = (e) => {
        e.preventDefault();
        // alert(username+password);
        setAlertMsg("");
        setalert(0);

        qry.append("username", username);

        var info;

        axios.post(url + "check-user.php", qry).then((response) => {
            if (response.data.status == 1) {
                // console.log(response.data);
                setCard(2);
                setOtpEmail(response.data.msg);
            }
            else {
                setAlertMsg(response.data.msg);
                setalert(1);
            }

            // console.log(response.data);

        }).catch(error => {
            console.log("error");
        });

    }

    const checkOtp = (e) => {
        e.preventDefault();
        // alert(username+password);
        setAlertMsg("");
        setalert(0);

        qry.append("username", username);
        qry.append("otp", otp);

        var info;

        axios.post(url + "check-otp.php", qry).then((response) => {
            if (response.data.status == 1) {
                // console.log(response.data);
                setCard(3);
            }
            else {
                setAlertMsg(response.data.msg);
                setalert(1);
            }

            // console.log(response.data);

        }).catch(error => {
            console.log("error");
        });

    }

    const resetP = (e) => {
        e.preventDefault();
        // alert(username+password);
        setAlertMsg("");
        setalert(0);

        if (newPassword.length < 6) {
            setAlertMsg("Password must be at least 6 digit");
            setalert(1);
        }
        else if (newPassword != rePassword) {
            setAlertMsg("New Password and Re Password must be same");
            setalert(1);
        }
        else {
            qry.append("username", username);
            qry.append("new-password", newPassword);
            qry.append("otp", otp);

            axios.post(url + "reset-password.php", qry).then((response) => {
                if (response.data.status == 1) {
                    console.log(response.data);
                    setAlertMsg(response.data.msg);
                    setAlertColor("crm-color-green");
                    setalert(1);
                    setTimeout(() => {
                        navigate("/login");
                    }, 1000);
                }
                else {
                    setAlertMsg(response.data.msg);
                    setalert(1);
                }

                // console.log(response.data);

            }).catch(error => {
                console.log("error");
            });
        }

    }

    return (
        <>
            <div className="login-page">
                <img className='' src={Brand} alt="swarnbhoomii-logo" />

                {card == 1 ?
                    <div className="login-card">
                        <h2> CRM    </h2>
                        <div>
                            <h3> Reset Password </h3>

                            <form onSubmit={(e) => checkUser(e)}>
                                <label>Username</label>
                                <br />
                                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                                <br />
                                {alert ?
                                    <>
                                        <br />
                                        <p className={alertColor}> {alertMsg} </p>
                                    </>
                                    : <></>
                                }

                                <button className=""> Send OTP</button>
                                <Link to="/login">Login</Link>
                            </form>

                        </div>
                    </div>
                    : card == 2 ?

                        <div className="login-card">
                            <h2> CRM    </h2>
                            <div>
                                <p> {otpEmail}</p>
                                <br />
                                <h3> OTP </h3>

                                <form onSubmit={(e) => checkOtp(e)}>
                                    <label>OTP</label>
                                    <br />
                                    <input type="number" value={otp} onChange={(e) => setOtp(e.target.value)} required />
                                    <br />
                                    {alert ?
                                        <>
                                            <br />
                                            <p className={alertColor}> {alertMsg} </p>
                                        </>
                                        : <></>
                                    }

                                    <button className=""> Submit</button>
                                    <Link to="/login">Login</Link>
                                </form>

                            </div>
                        </div>
                        : card == 3 ?
                            <div className="login-card">
                                <h2> CRM    </h2>
                                <div>
                                    <h3> Set New Password </h3>

                                    <form onSubmit={(e) => resetP(e)}>
                                        <label> New Password </label>
                                        <br />
                                        <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                                        <br />
                                        <label> Re-Enter Password </label>
                                        <br />
                                        <input type="password" value={rePassword} onChange={(e) => setRePassword(e.target.value)} required />
                                        <br />
                                        {alert ?
                                            <>
                                                <br />
                                                <p className={alertColor}> {alertMsg} </p>
                                            </>
                                            : <></>
                                        }

                                        <button className=""> Submit</button>
                                        <Link to="/login">Login</Link>
                                    </form>

                                </div>
                            </div>
                            : <></>
                }

            </div>


        </>
    );
}

export default ResetPassword;
